import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const Logo = () => {
	return (
		<img src={`https://app.wamm.ro/assets/img/logo-color.png`} alt="react logo" />
	);
};
