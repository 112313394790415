import React from 'react'

const LauncherIcon4 = () => {
    return (
        <svg
            focusable="false" 
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 16 16"
            strokeLinecap="round" 
            strokeLinejoin="round" 
            height="1.3em"
            width="1.3em"
            style={{ width: '32px', height: '32px' }}
        >
            <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 01-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 10-2 0 1 1 0 002 0zm4 0a1 1 0 10-2 0 1 1 0 002 0zm3 1a1 1 0 100-2 1 1 0 000 2z"
                strokeWidth="0" fill="currentColor" />
        </svg>
    )
}

export default LauncherIcon4