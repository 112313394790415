import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const NoDataOfflineMessages = ({
	width = 200,
	height = 200,
	...restProps
}: IconProps) => {
	return (
    <svg
    width="100%"
    height="100%"
    viewBox="0 0 2963 1782"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
  >
    <g id="Offline-data" >
      <g id="Bg">
        <path
          d="M2962.54,1593.37c-334.332,114.761 -844.215,188.133 -1415.03,188.133c-570.812,-0 -1080.69,-73.372 -1415.03,-188.133c334.332,-85.209 844.215,-139.687 1415.03,-139.687c570.812,0 1080.69,54.478 1415.03,139.687Z"
          style={{
            fill: "url(#_Linear1)",
          }}
        />
        <path
          d="M476.17,142.318c23.55,-16.22 52.08,-25.72 82.811,-25.72c80.712,-0 146.24,65.527 146.24,146.239l-704.913,0c-0.204,-4.147 -0.308,-8.32 -0.308,-12.518c0,-138.155 112.164,-250.319 250.319,-250.319c99.489,-0 185.5,58.166 225.851,142.318Z"
          style={{
            fill: "url(#_Linear2)",
          }}
        />
        <path
          d="M2397.37,219.734c-20.831,-14.348 -46.066,-22.751 -73.249,-22.751c-71.391,0 -129.351,57.961 -129.351,129.352l623.509,0c0.181,-3.668 0.272,-7.36 0.272,-11.073c0,-122.2 -99.211,-221.412 -221.412,-221.412c-88,0 -164.078,51.45 -199.769,125.884Z"
          style={{
            fill: "url(#_Linear3)",
          }}
        />
        <path
          d="M2654.73,917.082c-15.224,-10.486 -33.666,-16.627 -53.532,-16.627c-52.174,0 -94.533,42.359 -94.533,94.534l455.676,-0c0.133,-2.681 0.199,-5.379 0.199,-8.092c0,-89.308 -72.506,-161.814 -161.813,-161.814c-64.313,-0 -119.913,37.6 -145.997,91.999Z"
          style={{
            fill: "url(#_Linear4)",
          }}
        />
      </g>
      <g>
        <path
          d="M969.965,920.277c-161.971,-19.8 -287.59,-157.975 -287.59,-325.258c-0,-172.853 134.123,-314.627 303.885,-326.838c68.444,-157.737 225.632,-268.181 408.413,-268.181c220.944,0 404.493,161.38 439.103,372.595c1.951,-0.041 3.907,-0.061 5.867,-0.061c151.826,-0 275.089,123.263 275.089,275.088c-0,135.156 -97.683,247.678 -226.24,270.756c-15.855,2.847 -32.18,4.332 -48.849,4.332l-829.577,0c-13.574,0 -26.957,-0.827 -40.101,-2.433Z"
          style={{
            fill: "#bdc5cf",
            fillOpacity: 0.73,
          }}
        />
        <circle
          cx={1398.55}
          cy={531.772}
          r={203.556}
          style={{
            fill: "#ecedef",
          }}
        />
      </g>
      <g>
        <path
          d="M1520.33,482.872c0,-11.883 -9.648,-21.531 -21.531,-21.531l-200.49,0c-11.883,0 -21.531,9.648 -21.531,21.531l0,120.681c0,11.883 9.648,21.531 21.531,21.531l200.49,-0c11.883,-0 21.531,-9.648 21.531,-21.531l0,-120.681Z"
          style={{
            fill: "#bdc5cf",
            fillOpacity: 0.73,
          }}
        />
        <path
          d="M1249.46,442.529l153.164,107.041l145.027,-111.11l-298.191,4.069Z"
          style={{
            fill: "none",
            stroke: "#ecedef",
            strokeWidth: "21.37px",
          }}
        />
      </g>
      <g id="Man-5">
        <path
          d="M2133.49,1691.46l0,40.429l36.914,6.812l-0,-50.537l-36.914,3.296Z"
          style={{
            fill: "#f9b6b1",
          }}
        />
        <path
          d="M2407.95,1659.32l26.366,30.541c0,0 -28.783,20.796 -33.617,15.452c-4.834,-5.344 -16.26,-35.007 -16.26,-35.007l23.511,-10.986Z"
          style={{
            fill: "#f9b6b1",
          }}
        />
        <path
          d="M2390.88,1692.58l-8.583,46.347c-0,0 -29.525,1.03 -33.302,15.45c-3.776,14.419 1.717,27.122 1.717,27.122l56.304,-0.687c-0,0 40.855,-33.302 49.781,-79.306l-25.406,-24.032c0,-0 -3.239,16.044 -13.645,17.451c-10.406,1.406 -22.655,-7.362 -26.866,-2.345Z"
          style={{
            fill: "#eceef2",
          }}
        />
        <clipPath id="_clip5">
          <path d="M2390.88,1692.58l-8.583,46.347c-0,0 -29.525,1.03 -33.302,15.45c-3.776,14.419 1.717,27.122 1.717,27.122l56.304,-0.687c-0,0 40.855,-33.302 49.781,-79.306l-25.406,-24.032c0,-0 -3.239,16.044 -13.645,17.451c-10.406,1.406 -22.655,-7.362 -26.866,-2.345Z" />
        </clipPath>
        <g clipPath="url(#_clip5)">
          <path
            d="M2343.01,1772.09l58.446,-2.197c0,-0 34.936,-37.324 44.604,-76.903c9.668,-39.579 17.358,4.366 17.358,11.836c0,7.471 -49.877,101.292 -49.877,101.292l-62.181,-11.205c-0,-0 -23.071,7.308 -8.35,-22.823Z"
            style={{
              fill: "#062249",
            }}
          />
        </g>
        <path
          d="M2120.94,1711.68c-0,-0 -0.824,-2.747 6.042,1.648c6.866,4.394 19.226,18.676 28.839,15.106c9.613,-3.571 17.578,-7.416 18.951,-1.373c1.373,6.042 6.042,39 1.648,46.141l-131.285,0c0,0 -5.218,-21.148 5.493,-28.838c10.712,-7.691 27.74,-6.043 31.86,-7.416c4.12,-1.373 30.212,-17.852 38.452,-25.268Z"
          style={{
            fill: "#eceef2",
          }}
        />
        <clipPath id="_clip6">
          <path d="M2120.94,1711.68c-0,-0 -0.824,-2.747 6.042,1.648c6.866,4.394 19.226,18.676 28.839,15.106c9.613,-3.571 17.578,-7.416 18.951,-1.373c1.373,6.042 6.042,39 1.648,46.141l-131.285,0c0,0 -5.218,-21.148 5.493,-28.838c10.712,-7.691 27.74,-6.043 31.86,-7.416c4.12,-1.373 30.212,-17.852 38.452,-25.268Z" />
        </clipPath>
        <g clipPath="url(#_clip6)">
          <path
            d="M2043.68,1761.38l145.566,0l-9.887,36.255l-138.7,-11.81l3.021,-24.445Z"
            style={{
              fill: "#062249",
            }}
          />
        </g>
        <path
          d="M2182.96,1137.76l-1.609,50.425l-70.81,218.865l16.093,297.721l53.107,0.537c0,-0 9.656,-233.886 9.12,-278.41l40.232,-45.597l1.073,57.935l146.447,249.442l43.987,-28.967c0,-0 -96.558,-202.772 -115.333,-235.495c0,0 38.623,-161.467 39.696,-189.361c1.073,-27.895 -2.682,-96.022 -2.682,-96.022l-159.321,-1.073Z"
          style={{
            fill: "#062249",
          }}
        />
        <path
          d="M2031.05,857.13c0,0 -11.535,-0.824 -26.367,-10.162c-14.831,-9.338 -25.543,-43.944 -17.852,-40.374c7.69,3.571 15.38,6.592 15.38,6.592l-6.042,-13.458c0,-0 4.394,-4.12 12.085,3.021c7.69,7.141 20.324,16.479 25.268,24.169c4.944,7.691 -2.472,30.212 -2.472,30.212Z"
          style={{
            fill: "#f9b6b1",
          }}
        />
        <path
          d="M2240.95,1139.7l-7.383,11.601l27.422,17.05l12.48,-14.238l-32.519,-14.413Z"
          style={{
            fill: "#f9b6b1",
          }}
        />
        <path
          d="M2137.47,651.116c-0,-0 0.549,23.895 5.218,32.134c4.669,8.24 -12.359,35.98 -12.359,35.98l13.183,4.669c-0,-0 7.131,35.402 20.072,37.199c6.328,0.879 29.003,-3.516 34.98,0.352c5.976,3.867 8.437,18.984 8.437,18.984l67.045,-11.423c-0,-0 -5.713,-33.243 -18.896,-48.185c-0,0 20.352,-61.701 6.962,-86.419c-13.389,-24.719 -40.511,-44.975 -74.5,-42.915c-33.988,2.06 -37.421,26.435 -54.587,21.286c-17.166,-5.15 -15.184,-17.3 -15.184,-17.3c0,0 -25.992,52.101 19.629,55.638Z"
          style={{
            fill: "#f9b6b1",
          }}
        />
        <clipPath id="_clip7">
          <path d="M2137.47,651.116c-0,-0 0.549,23.895 5.218,32.134c4.669,8.24 -12.359,35.98 -12.359,35.98l13.183,4.669c-0,-0 7.131,35.402 20.072,37.199c6.328,0.879 29.003,-3.516 34.98,0.352c5.976,3.867 8.437,18.984 8.437,18.984l67.045,-11.423c-0,-0 -5.713,-33.243 -18.896,-48.185c-0,0 20.352,-61.701 6.962,-86.419c-13.389,-24.719 -40.511,-44.975 -74.5,-42.915c-33.988,2.06 -37.421,26.435 -54.587,21.286c-17.166,-5.15 -15.184,-17.3 -15.184,-17.3c0,0 -25.992,52.101 19.629,55.638Z" />
        </clipPath>
        <g clipPath="url(#_clip7)">
          <path
            d="M2204.58,688.205c0,-0 -3.955,-23.511 6.152,-29.883c10.108,-6.372 35.156,4.395 27.246,22.632c-7.91,18.237 -10.327,24.829 -10.327,24.829l26.806,13.403c0,-0 30.803,-18.649 24.28,-71.864c-6.524,-53.214 -57.334,-75.529 -57.334,-75.529l-77.247,6.179l-50.467,10.643l14.762,66.947c0,0 23.632,1.005 37.835,-7.151c14.203,-8.156 31.359,-17.156 36.843,-12.234c5.484,4.921 12.484,51.604 21.451,52.028"
            style={{
              fill: "#332e30",
            }}
          />
        </g>
        <path
          d="M2041.63,828.081l-24.994,32.683c0,0 130.55,107.939 135.312,107.939c4.762,0 26.314,-22.521 26.314,-22.521c0,-0 0.112,107.586 -2.085,136.15c-2.197,28.564 4.395,55.755 4.395,55.755l56.029,-1.099l35.98,25.543l22.968,-23.727l48.339,-0.275c-0,0 -1.232,-53.163 -3.704,-77.057c0,-0 28.29,-47.241 32.135,-57.128c3.845,-9.888 -35.431,-97.777 -35.431,-97.777c0,-0 5.472,-118.93 -71.088,-161.844c0,-0 -66.26,28.152 -68.32,38.795c-2.06,10.642 -16.479,84.456 -16.479,84.456l-29.869,28.838c0,0 -108.113,-67.77 -109.502,-68.731Z"
          style={{
            fill: "#7f58ff",
          }}
        />
        <path
          d="M2289.97,932.358c-0,-0 12.74,53.643 20.116,69.065c7.376,15.422 -56.325,101.922 -56.325,101.922c-0,0 53.643,-62.36 36.209,-170.987Z"
          style={{
            fill: "#5125df",
          }}
        />
        <path
          d="M2232.26,1378.54c0,0 75.186,-96.471 86.516,-123.937c-0,0 -6.18,38.795 -12.36,51.498c-6.179,12.702 -74.156,72.439 -74.156,72.439Z"
          style={{
            fill: "#06152b",
          }}
        />
        <path
          d="M2015.11,817.74l-22.395,-49.594l-26.177,0l23.525,53.429l25.047,-3.835Z"
          style={{
            fill: "#332e30",
          }}
        />
        <path
          d="M2008.31,810.724l-16.483,-37.582l-18.473,0l17.333,40.488l17.623,-2.906Z"
          style={{
            fill: "#ecedef",
          }}
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="_Linear1"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(19.6802,580.46,-580.46,19.6802,1705.91,1123.5)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <linearGradient
        id="_Linear2"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(13.098,639.151,-639.151,13.098,269.697,-376.313)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <linearGradient
        id="_Linear3"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-11.5854,565.341,565.341,11.5854,2580,-239.006)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
      <linearGradient
        id="_Linear4"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-8.46692,413.166,413.166,8.46692,2788.2,581.822)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#bdc5cf",
            stopOpacity: 0,
          }}
        />
      </linearGradient>
    </defs>
  </svg>
    		)};
